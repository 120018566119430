import { memo } from "react"
import { Box, Text, HStack, VStack } from "@chakra-ui/react"
import { CustomImage } from "@components/Image"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useSanityImageUrl } from "@app/hooks/useSanity"

type ArticleHeroAuthorBadgeProps = {
  item: any
}
// TODO: link this to the authors article listings after setup

const ArticleHeroAuthorBadge: React.FC<ArticleHeroAuthorBadgeProps> = ({ item }) => {
  const { author, authorImage } = item || {}
  const { getSanityImageUrl } = useSanityImageUrl()

  const locales = useLocale(LOCALE_KEYS.ARTICLE)

  if (!author) return null

  const writtenByText = locales?.writtenBy
  const imageUrl = authorImage?.url ? getSanityImageUrl(authorImage?.url, 60, 60) : ""

  return (
    <HStack spacing={[0, 0, 2]} flexDirection={["column", "column", "row"]}>
      <Box mb={[4, 4, 0]}>
        <CustomImage src={imageUrl} width={15} minW={15} borderRadius="50%" />
      </Box>
      <VStack alignItems={["center", "center", "flex-start"]}>
        {writtenByText && <Text fontSize="sm">{writtenByText}</Text>}
        <Text fontFamily="heading" fontSize="xl">
          {author}
        </Text>
      </VStack>
    </HStack>
  )
}

const MemoArticleHeroAuthorBadge = memo(ArticleHeroAuthorBadge)
export { MemoArticleHeroAuthorBadge as ArticleHeroAuthorBadge }
