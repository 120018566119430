import { memo, useMemo } from "react"
import { Box, Heading, Container, VStack, Text, StackDivider, Stack, Tag, HStack } from "@chakra-ui/react"
import { ArticleHeroAuthorBadge } from "./ArticleHeroAuthorBadge"
import { CustomImage } from "@components/Image"
import { useDate } from "@app/hooks/useDate"
import { SocialLinks } from "@components/SocialMedia"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { BackButton } from "@components/BackButton"
import { useConfigContext } from "@app/providers/config"
import { useSanityImageUrl } from "@app/hooks/useSanity"

type ArticleHeroProps = {
  item: any
}

const ArticleHero: React.FC<ArticleHeroProps> = ({ item }) => {
  const { getArticleDate } = useDate()
  const { formattedDate: articlePublishedAt } = useMemo(
    () => getArticleDate(item?.attributes?.publishedAt),
    [getArticleDate, item?.attributes?.publishedAt]
  )
  const { getSanityImageUrl } = useSanityImageUrl()

  const locales = useLocale(LOCALE_KEYS.ARTICLE)
  const {
    settings: { routes },
  } = useConfigContext()

  if (!item) return null

  const hasCategories = !!item?.categories?.length
  const postedOnText = locales?.postedOn

  const imageUrl = item?.image?.src ? getSanityImageUrl(item?.image?.src, 405, 530) : ""

  return (
    <Box bgColor="brand.peachy" pt={[16, 16, 18, 16]} pb={[11, 16]}>
      <Box position="absolute" top={[5, 8]} left={[5, 5, 12]} display={["none", "block"]}>
        <BackButton fallback={routes.ARTICLE} />
      </Box>

      <Container>
        <Stack
          direction={["column-reverse", "column-reverse", "row"]}
          spacing={[6, 6, 20]}
          justifyContent="space-around"
          alignItems="center"
        >
          <Box w={["full", "full", "45%"]}>
            {hasCategories && (
              // TODO: refactor all these to use chakra `Tag` and add to tokens
              <HStack mb={4} justifyContent={["center", "center", "unset"]} spacing={0} gap={1} wrap="wrap">
                {item?.categories?.map((category: any) => (
                  // TODO: fix these links, 404ing atm
                  // <CustomLink
                  //   py={2}
                  //   px={4}
                  //   borderRadius="46px"
                  //   bg="brand.offWhite"
                  //   border="1px"
                  //   key={category?.title}
                  //   to={category.url}
                  //   title={category.title}
                  // >
                  //   {category.title}
                  // </CustomLink>
                  <Tag key={category?.title} variant="article-hero">
                    {category?.title}
                  </Tag>
                ))}
              </HStack>
            )}
            <Heading as="h1" size="h2" mb={12} textAlign={["center", "center", "left"]}>
              {item?.title}
            </Heading>
            <Stack spacing={6} direction={["column", "column", "row"]} divider={<StackDivider borderColor="brand.avocado" />}>
              <Stack spacing={[2, 2, 6]} direction={["column", "column", "row"]} alignItems="center">
                <ArticleHeroAuthorBadge item={item} />
                {articlePublishedAt && (
                  <VStack alignItems={["center", "center", "flex-start"]}>
                    {postedOnText && <Text fontSize="sm">{postedOnText}</Text>}
                    <Text fontFamily="heading" fontSize="xl">
                      {articlePublishedAt}
                    </Text>
                  </VStack>
                )}
              </Stack>
              <Box alignSelf="center">
                <SocialLinks orientation="horizontal" />
              </Box>
            </Stack>
          </Box>
          <Box w={["full", "full", "30%"]} maxH="600px">
            <CustomImage maxH="full" width="437px" src={imageUrl} borderRadius="300px" mx={["auto", "auto", "unset"]} />
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

const MemoArticleHero = memo(ArticleHero)
export { MemoArticleHero as ArticleHero }
