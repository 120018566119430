import { Container, Box } from "@chakra-ui/react"
import { useRoutes } from "@app/hooks/useRoutes"
import { ArticleHero } from "./ArticleHero"
import { SocialLinks } from "@components/SocialMedia"
import { useContent } from "@app/hooks/useContent"
import { memo, useMemo } from "react"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/templates/article"
import type { PageProps } from "@root/types/global"

const Article: React.FC<PageProps<Props>> = ({ article }) => {
  const { urlResolver } = useRoutes()
  const { getContent } = useContent({ type: "article" })

  const item = useMemo(
    () => ({
      ...article,
      author: article?.attributes?.author?.name || "",
      tags: article?.attributes?.tags || [],
      categories: article?.categories?.map(urlResolver) || [],
      image: {
        alt: article?.image?.alt || "",
        src: article?.image?.asset?.url || "",
      },
      content: getContent({ content: article?.content }),
      authorImage: article?.attributes?.author?.image?.asset || {},
    }),
    [article, getContent, urlResolver]
  )

  return (
    <Box as="article" bg="brand.offWhite" overflow="hidden" position="relative">
      <ArticleHero item={item} />

      <Box position="relative" mt={[8, 42]} mb={[15, 42]} alignItems="flex-start">
        <Box position="absolute" top={0} left={0}>
          <Box pl={11} position="sticky" left={11} top={11} display={["none", "none", "block"]}>
            <SocialLinks orientation="vertical" />
          </Box>
        </Box>
        <Container>
          <Box maxW={["unset", "unset", "80%", 100]} mx="auto" textAlign="left">
            {item?.content}
          </Box>
        </Container>
      </Box>
      <Sections page={{ sections: article?.sections }} />
    </Box>
  )
}

export default memo(Article)
