import { memo } from "react"
import { Text, HStack } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { useRoutes } from "@app/hooks/useRoutes"

type BackButtonProps = {
  label?: string
  fallback?: string
}

const BackButton: React.FC<BackButtonProps> = ({ label = "Back", fallback }) => {
  const { goBack } = useRoutes()

  return (
    <HStack role="group" cursor="pointer" pr={4} onClick={() => goBack({ fallback })}>
      <ArrowBackIcon mr={1} w={4} _groupHover={{ transform: "translateX(-4px)" }} transition="100ms ease-out" />
      <Text fontSize="md">{label}</Text>
    </HStack>
  )
}

const MemoBackButton = memo(BackButton)
export { MemoBackButton as BackButton }
