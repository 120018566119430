import { graphql, PageProps } from "gatsby"
import Template from "@components/Article/Article"

export type Props = PageProps<GatsbyTypes.TemplateArticleQuery, GatsbyTypes.TemplateArticleQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query TemplateArticle($id: String!) {
    article: sanityArticle(_id: { eq: $id }) {
      ...SanityArticleFragment
    }
    template: sanityTemplateArticle {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      additionalRelatedText
      settingShowRelated
    }
  }
  fragment SanityArticleFragment on SanityArticle {
    title
    _id
    description
    handle {
      current
    }
    url
    _createdAt
    metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    content: _rawContent(resolveReferences: { maxDepth: 9 })
    image: _rawImage(resolveReferences: { maxDepth: 2 })
    categories: _rawCategories(resolveReferences: { maxDepth: 4 })
    attributes: _rawAttributes(resolveReferences: { maxDepth: 4 })
    sections: _rawSections(resolveReferences: { maxDepth: 8 })
    _type
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
